import { ROLE } from '@/features/user/types'
import { useAppSelector } from '@/store'

export const useChatConversationData = () => {
  const { data, backgroundUnreadMessages } = useAppSelector((state) => state.chat.conversation.data)

  const getParticipants = () => data?.participants

  const getParticipantsBabysitters = () =>
    data?.participants?.filter((participant) => participant.role == ROLE.Babysitter)
  const getParticipantsClient = () =>
    data?.participants?.filter((participant) => participant.role == ROLE.Client)
  const getParticipantsBabysittersNotMe = (id: number) =>
    data?.participants?.filter(
      (participant) => participant.role == ROLE.Babysitter && participant.id != id
    )

  return {
    conversation: data,
    backgroundUnreadMessages,
    getParticipants,
    getParticipantsBabysitters,
    getParticipantsClient,
    getParticipantsBabysittersNotMe,
  }
}
