import { IconCirclePlus } from '@tabler/icons'
import { IconCalendar, IconClock } from '@tabler/icons'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Anchor, Grid, Group, Title } from '@mantine/core'
import { ActionIconButton } from '@/components/Elements'
import {
  DatePickerController,
  FormControlActionBlock,
  TimeInputController,
} from '@/components/FormElements'
import { FieldError } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'

export type OnetimeRangeFieldValues = {
  range_date_start: string | Date | null
  range_date_end: string | Date | null
  range_time_start: string | Date | null
  range_time_end: string | Date | null
}

interface IProps {
  name: string
  control: any
  limit?: number
  meta?: any
  clearErrors?: (name: any) => void
  gridProps?: any
}

export const OnetimeRangesFieldArray = ({
  name,
  control,
  meta,
  clearErrors,
  gridProps,
}: IProps) => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()
  const error = meta?.error

  const { watch, trigger } = useFormContext()

  const watchDateEnd = watch('date_end')

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: name,
    keyName: 'fieldId',
  })

  const addRow = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    if (clearErrors) {
      clearErrors(name)
    }

    const fields: OnetimeRangeFieldValues = {
      range_date_start: null,
      range_date_end: null,
      range_time_start: null,
      range_time_end: null,
    }

    append(fields)
  }

  const removeRow = (index: number) => {
    remove(index)
  }

  const renderAdd = () => (
    <div className={'flex flex-col self-start'}>
      <Anchor href="#" onClick={addRow} className={'font-bold add-new-item'}>
        <Group className={'gap-2'}>
          <IconCirclePlus size={18} />
          {t('add_dates')}
        </Group>
      </Anchor>
      <FieldError error={error} />
    </div>
  )
  const renderTitleSection = () => (
    <Title order={5} mb={'sm'} color={'primary'}>
      {t('added_date')}
    </Title>
  )

  return (
    <div className={'flex flex-col mb-3'}>
      {fields.map((item, index) => (
        <div key={item.fieldId} className={'flex flex-col'}>
          {renderTitleSection()}
          <Grid grow {...gridProps}>
            <Grid.Col span={mobileView ? 6 : 4}>
              <DatePickerController
                control={control}
                name={`${name}.${index}.range_date_start`}
                id={`${name}.${index}.range_date_start`}
                label={t('start_date')}
                size={mobileView ? 'md' : 'lg'}
                placeholder={t('start_date')}
                withinPortal
                clearable={false}
                mb={'md'}
                translateParams={{ date: t('today') }}
                minDate={new Date()}
                initialMonth={watchDateEnd || new Date()}
                icon={<IconCalendar />}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>

            <Grid.Col span={mobileView ? 5 : 3}>
              <TimeInputController
                control={control}
                name={`${name}.${index}.range_time_start`}
                id={`${name}.${index}.range_time_start`}
                size={mobileView ? 'md' : 'lg'}
                label={t('from')}
                icon={<IconClock />}
                mb={'md'}
                className={mobileView ? 'ml-2' : ''}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>
            <Grid.Col span={mobileView ? 1 : 5} />
          </Grid>

          <Grid>
            <Grid.Col span={mobileView ? 6 : 4}>
              <DatePickerController
                control={control}
                name={`${name}.${index}.range_date_end`}
                id={`${name}.${index}.range_date_end`}
                size={mobileView ? 'md' : 'lg'}
                label={t('end_date')}
                placeholder={t('end_date')}
                mb={'md'}
                withinPortal
                clearable={false}
                translateParams={{ date: t('start_date') }}
                minDate={new Date()}
                icon={<IconCalendar />}
                initialMonth={watchDateEnd || new Date()}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>

            <Grid.Col span={mobileView ? 5 : 3}>
              <TimeInputController
                control={control}
                name={`${name}.${index}.range_time_end`}
                id={`${name}.${index}.range_time_end`}
                size={mobileView ? 'md' : 'lg'}
                label={t('to')}
                icon={<IconClock />}
                mb={'md'}
                className={mobileView ? 'ml-2' : ''}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>
            <Grid.Col span={mobileView ? 1 : 5}>
              <FormControlActionBlock className={mobileView ? 'mt-4' : 'mb-4'}>
                <ActionIconButton name={'remove'} onClick={() => removeRow(index)} />
              </FormControlActionBlock>
            </Grid.Col>
          </Grid>
        </div>
      ))}
      {renderAdd()}
    </div>
  )
}
