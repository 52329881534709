import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { chatsUsersAPI } from '@/features/chat/api'
import { SendMessageToUserDTO } from '@/features/chat/api/dto'
import { chatConversationMessagesList } from '@/features/chat/store/conversation/messages/list/slice'
import { ChatModel } from '@/features/chat/types/models'
import { RootState } from '@/store'
import { FormTypes } from '@/types'
import { chatUpdateOrCreate } from '../../../chats/list/saga'
import {
  CHAT_CONVERSATION_MESSAGES_SEND_FAILED,
  CHAT_CONVERSATION_MESSAGES_SEND_REQUEST,
  CHAT_CONVERSATION_MESSAGES_SEND_SUCCESS,
} from './actionTypes'

type ArgsType = {
  activeChat: ChatModel | null
  params: SendMessageToUserDTO
}

export const chatConversationMessagesSendPA = createPromiseAction(
  CHAT_CONVERSATION_MESSAGES_SEND_REQUEST,
  CHAT_CONVERSATION_MESSAGES_SEND_SUCCESS,
  CHAT_CONVERSATION_MESSAGES_SEND_FAILED
)<ArgsType, unknown, AxiosError<FormTypes.ValidationErrors>>()

const conversationState = (state: RootState) => state.chat.conversation.data.data

function* send(action: PromiseAction<string, ArgsType, any>) {
  try {
    const { params, activeChat } = action.payload

    const response: AxiosResponse = yield call(chatsUsersAPI.sendMessageToUser, params, activeChat)
    const { data } = response

    yield put(chatConversationMessagesSendPA.success({}))

    const message = data?.data

    // update message feed after send message
    const conversation: {
      id: number
    } = yield select(conversationState)

    yield put(chatConversationMessagesList.addMessage(message))

    resolvePromiseAction(action, {})

    // update chat list
    const chatListPayload = {
      message,
      chat: {
        id: message.chat_id,
      },
    }
    yield call(chatUpdateOrCreate as any, { payload: chatListPayload })
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* sendSaga() {
  yield takeEvery(chatConversationMessagesSendPA.request, send)
}
