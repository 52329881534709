import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {},

  messageBlock: {
    display: 'flex',
    marginBottom: theme.spacing.xl,
    position: 'relative',
    marginRight: theme.spacing.xl,
  },

  right: {
    justifyContent: 'flex-end',
    marginRight: 0,
    marginLeft: theme.spacing.xl,
  },
  avatarGap: {
    marginRight: '4px',
  },
  avatarHidden: {
    width: '44px',
  },
}))
