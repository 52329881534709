import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { Text } from '@mantine/core'
import { CurrencyFormat } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import {
  BookingDatePeriodDisplay,
  BookingHoursPerWeekDisplay,
  BookingTimePeriodDisplay,
} from '@/features/bookings/components/Elements'
import { bookingHelper } from '@/features/bookings/helpers'
import { BookingService } from '@/features/bookings/types/models'
import { getAsArrayIfNot } from '@/helpers/common/utils'
import { CommonUtils, DateUtils } from '@/utils'
import { InfoItem } from './InfoItem'

const { bookingModel } = bookingHelper

interface IProps {
  data: BookingService
}

export const InfoBlock = ({ data }: IProps) => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  const {
    date_start,
    date_end,
    primary_language,
    secondary_language,
    booking_children,
    babysitter_amount,
    current_bbs_amount,
  } = data

  const timePeriod = bookingModel.getTimePeriod(data)

  return (
    <div className={mobileView ? 'flex flex-wrap justify-between' : 'flex flex-wrap gap-10'}>
      {!mobileView && (
        <>
          <InfoItem title={t('date')}>
            <BookingDatePeriodDisplay startDate={date_start} endDate={date_end} />
          </InfoItem>

          {timePeriod && (
            <InfoItem title={t('time')}>
              <BookingTimePeriodDisplay timePeriod={timePeriod} />
            </InfoItem>
          )}
          {data.hours_per_week && (
            <>
              <Text size={14} weight={700}>
                <BookingHoursPerWeekDisplay hoursPerWeek={data.hours_per_week} />
              </Text>
            </>
          )}
        </>
      )}

      <InfoItem title={t('language')}>
        <React.Fragment key={'primary'}>
          {primary_language?.name}
          <br />
        </React.Fragment>

        {secondary_language && (
          <Group>
            {getAsArrayIfNot(secondary_language).map((language: any, index) => (
              <React.Fragment key={'secondary' + index}>{language?.name}</React.Fragment>
            ))}
          </Group>
        )}
      </InfoItem>

      <InfoItem title={t('children')}>
        {booking_children.map((item, i) => (
          <React.Fragment key={i}>
            {DateUtils.formatDate(item?.birthday)}
            <br />
          </React.Fragment>
        ))}
      </InfoItem>

      <InfoItem title={t('total_amount')}>
        {!CommonUtils.isNil(current_bbs_amount) ? (
          <CurrencyFormat value={current_bbs_amount as number} />
        ) : (
          '-'
        )}
      </InfoItem>
    </div>
  )
}
