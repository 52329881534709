import { ChatNavControl } from '@/features/chat/components/Elements'
import { ChatModel, Participant } from '@/features/chat/types/models'
import { BabysitterUser } from '@/features/user'

interface IProps {
  babysitters: BabysitterUser[]
  chat: ChatModel
}

export const ChatControl = ({ babysitters, chat }: IProps) => {
  const result: Participant[] = []

  babysitters.map((babysitter) => {
    result.push({
      id: babysitter.id,
      avatar: babysitter.avatar,
      first_name: babysitter.first_name,
      last_name: babysitter.last_name,
      role: babysitter.role,
    })
  })

  return <ChatNavControl chat={chat} participants={result} />
}
