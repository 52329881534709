import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Text, useMantineTheme } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import {
  BookingHoursPerWeekDisplay,
  BookingTimePeriodDisplay,
} from '@/features/bookings/components/Elements'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { bookingHelper } from '@/features/bookings/helpers'
import { BookingService } from '@/features/bookings/types/models'
import { useUsersModel } from '@/features/users/hooks'
import { DateUtils } from '@/utils'
import useStyles from './InfoTopBarBlock.styles'

const getType = bookingHelper.type.getType

const { bookingModel } = bookingHelper
interface IProps {
  data: BookingService
}

export const InfoTopBarBlock = ({ data }: IProps) => {
  const theme = useMantineTheme()
  const {
    appState: { mobileView },
  } = useAppState()

  const [dateEndToRender, setDateEndToRender] = useState(data.date_end)
  const { classes } = useStyles()

  const { user, type, date_start, date_end, timetable } = data

  const timePeriod = bookingModel.getTimePeriod(data)
  const { getName } = useUsersModel()

  useEffect(() => {
    if (
      (type === BOOKING_TYPE.ONETIME || type === BOOKING_TYPE.ANIMATION) &&
      timetable?.onetime_ranges &&
      timetable.onetime_ranges.length > 0
    ) {
      let maxDate = moment(date_end)
      timetable?.onetime_ranges?.forEach(function (range: any) {
        if (maxDate.isBefore(moment(range.range_date_end))) {
          maxDate = moment(range.range_date_end)
          setDateEndToRender(range.range_date_end)
        }
      })
    }
  }, [])

  const renderDateMobile = (date: string) => {
    const dayWeek = DateUtils.format(date, 'ddd')
    const day = DateUtils.format(date, 'D')
    const month = DateUtils.format(date, 'MMM')

    return (
      <div className={'flex gap-1'}>
        <Text size={14} weight={700} color={'secondary'}>
          {dayWeek}
        </Text>
        <Text size={14} weight={700}>
          {day}
        </Text>
        <Text size={14} weight={700}>
          {month}
        </Text>
      </div>
    )
  }

  return (
    <>
      {mobileView && (
        <div className={classes.dateBlock}>
          <div className={'flex gap-1 mb-2'}>
            {date_start === dateEndToRender ? (
              renderDateMobile(dateEndToRender)
            ) : (
              <>
                {renderDateMobile(date_start)}

                <Text size={14} weight={700}>
                  {' - '}
                </Text>

                {renderDateMobile(dateEndToRender)}
              </>
            )}
            {timePeriod && (
              <>
                <Text size={14} weight={700}>
                  {', '}
                </Text>
                <Text size={14} weight={700}>
                  <BookingTimePeriodDisplay timePeriod={timePeriod} />
                </Text>
              </>
            )}
            {data.hours_per_week && (
              <>
                <Text size={14} weight={700}>
                  {', '}
                </Text>
                <Text size={14} weight={700}>
                  <BookingHoursPerWeekDisplay hoursPerWeek={data.hours_per_week} />
                </Text>
              </>
            )}
          </div>
        </div>
      )}

      <div className={'flex flex-wrap gap-2 items-center'}>
        <Text size={mobileView ? 'md' : 'lg'} weight={700}>
          {getName(user)}
        </Text>

        <Icon name={getType(type)?.iconName} color={theme.colors.gray[6]} size={16} />
      </div>
    </>
  )
}
