import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  bookingsRatingBabysitterCreatePA,
  bookingsRatingClientCreatePA,
} from '@/features/bookings/store'
import { BookingModel, BookingService } from '@/features/bookings/types/models'
import { useRatingEntryPopupModuleContext } from '@/features/rating/contexts'
import { BabysitterUser, useUser } from '@/features/user'
import { useNotify } from '@/hooks'
import { RatingEntryForm } from '../../RatingEntryForm'

interface IProps {
  booking: BookingModel | BookingService
  onClose: () => void
  onDestroy: () => void
}

export const RatingEntryCreateContainer = ({ booking, onClose, onDestroy }: IProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => onDestroy()
  }, [])

  const { onRatingSubmitted } = useRatingEntryPopupModuleContext()

  const { user, isBabysitter, isClient } = useUser()

  const onSuccess = (data: any) => {
    onRatingSubmitted && onRatingSubmitted(data, { action: 'create', entity: user?.role })
  }

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const onSubmit = async (values: any) => {
    const params = {
      ...values,
    }

    let data

    if (isBabysitter()) {
      data = await dispatch(bookingsRatingClientCreatePA.request({ id: booking.id, params }))
    } else {
      data = await dispatch(
        bookingsRatingBabysitterCreatePA.request({
          id: booking.id,
          params,
        })
      )
    }
    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })

    onSuccess(data)
  }

  let initialValues = {}
  if (isClient()) {
    const babysittersInitialValues = [] as any[]
    booking.babysitters?.map((item: BabysitterUser) => {
      babysittersInitialValues.push({
        person: null,
        babysitter_feedback: null,
        same_babysitter: false,
        babysitter: {
          id: item.id,
          avatar: item.avatar,
          first_name: item.first_name,
          last_name: item.last_name,
        },
      })
    })
    initialValues = {
      value: null,
      platform: null,
      other_feedback: null,
      babysitter_ratings: babysittersInitialValues,
    }
  }
  return (
    <>
      {isBabysitter() ? (
        <RatingEntryForm booking={booking} onSubmit={onSubmit} onCancel={onClose} />
      ) : (
        <RatingEntryForm
          booking={booking}
          initialValues={initialValues}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      )}
    </>
  )
}
