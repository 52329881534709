import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MdClose, MdEmojiEmotions, MdOutlineImage, MdSend } from 'react-icons/md'
import { connect } from 'react-redux'
import { ActionIcon, Button, Group, Stack } from '@mantine/core'
import { TextInputController } from '@/components/FormElements'
import { IMAGE_CHAT_MAX_SIZE } from '@/const/core'
import { useAppState } from '@/features/app/hooks'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { chatsAPI } from '@/features/chat/api'
import { useUser } from '@/features/user'
import { useLang, useNotify } from '@/hooks'
import { RootState } from '@/store'
import { FileUtils, ValidationsUtils } from '@/utils'
import useStyles from './InputSection.styles'

interface IProps {
  onSubmit: () => void
  inputRef: any
  activeChat: any
  cleanImage: boolean
}

const InputSectionComponent = (props: IProps) => {
  const { t } = useTranslation()
  const { isBabysitter } = useUser()
  const { classes } = useStyles()
  const { lang } = useLang()
  const { showNotification } = useNotify()
  const [lastDate, setLastDate] = useState(props.activeChat?.booking?.date_end)
  const [file, setFile] = useState<File | null>(null)
  const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null)
  const [imageSubmitting, setImageSubmitting] = useState(false)
  const [pickerShown, setPickerShown] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    appState: { mobileView },
  } = useAppState()
  const { control, watch, formState, setValue, getValues } = useFormContext()
  const watchBody = watch('body')

  const showKidsImage = useMemo(() => {
    const [hour, minute] = props.activeChat?.booking?.time_start?.split(':') ?? [0, 0]

    return (
      isBabysitter() &&
      props.activeChat &&
      props.activeChat.booking &&
      !props.activeChat.kids_image_sent &&
      !selectedPhoto &&
      !pickerShown &&
      moment(props.activeChat.booking.date_start).hour(hour).minute(minute).isBefore(moment()) &&
      moment().isBefore(lastDate)
    )
  }, [props.activeChat, selectedPhoto, pickerShown, lastDate])

  const selectPhoto = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setPickerShown(false)
    setValue('kids_image_upload', false)

    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const selectPhotoKids = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setValue('kids_image_upload', true)
    setPickerShown(false)

    if (inputRef.current) {
      inputRef.current.click()
    }
  }
  const removePhoto = () => {
    setSelectedPhoto(null)
    setFile(null)
    setValue('kids_image_upload', false)
    setValue('image', null)
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return

    const file = event.target.files[0]

    const isValid = ValidationsUtils.fileMaxSize(file, IMAGE_CHAT_MAX_SIZE)

    if (!isValid) {
      const message = t('file.max_size.error', {
        text: `${FileUtils.formatBytes(IMAGE_CHAT_MAX_SIZE)}`,
      })
      showNotification({ type: 'error', message: message })

      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setSelectedPhoto(reader.result as string)
      }
    }
    reader.readAsDataURL(file)
    setFile(file)

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  const onUpload = async (file: File) => {
    setImageSubmitting(true)
    const formData = new FormData()
    formData.append('file', file)
    const { data } = await chatsAPI.uploadImage(formData)
    setValue('image', data)
    return
  }

  useEffect(() => {
    if (
      (props.activeChat?.booking?.type === BOOKING_TYPE.ONETIME ||
        props.activeChat?.booking?.type === BOOKING_TYPE.ANIMATION) &&
      props.activeChat?.booking?.timetable?.onetime_ranges
    ) {
      props.activeChat?.booking?.timetable?.onetime_ranges.forEach(function (range: any) {
        if (lastDate > range.range_date_end) {
          setLastDate(moment(range.range_date_end))
        }
      })
    }
  }, [])

  useEffect(() => {
    if (props.cleanImage) {
      removePhoto()
      setImageSubmitting(false)
    }
  }, [props.cleanImage])

  const changePicker = () => {
    setPickerShown(!pickerShown)
  }

  const onKeyPress = (e: any) => {
    const { key, value } = e
    if (key === 'Enter') {
      beforeSubmit()
    }
  }

  const addEmoji = (e: any) => {
    const sym = e.unified.split('-')
    const codesArray: any[] = []
    sym.forEach((el: any) => codesArray.push('0x' + el))
    const emoji = String.fromCodePoint(...codesArray)
    const [body] = getValues(['body'])
    setValue('body', body + emoji)
  }

  const beforeSubmit = async () => {
    if (file) {
      try {
        await onUpload(file)
      } catch (error) {
        showNotification({
          type: 'error',
          message: t('errorSendingChatMessage'),
        })
        setImageSubmitting(false)
        return
      }
    }
    setPickerShown(false)
    props.onSubmit()
  }

  return (
    <Stack>
      <input
        type="file"
        id="image"
        name="image"
        onChange={onInputChange}
        ref={inputRef}
        accept="image/png, image/jpeg"
        className={'hidden'}
      />

      {(pickerShown || selectedPhoto) && (
        <div className={classes.hoverWidgets}>
          {pickerShown && (
            <Group className={'flex gap-0'}>
              <div className={classes.iconPicker}>
                <ActionIcon
                  onClick={() => changePicker()}
                  variant="filled"
                  radius="xl"
                  color={'primary'}
                >
                  <MdClose size="16" />
                </ActionIcon>
              </div>
              <Picker
                open={pickerShown}
                data={data}
                onEmojiSelect={addEmoji}
                locale={lang}
                theme="light"
                previewPosition="none"
              />
            </Group>
          )}
          {selectedPhoto && (
            <Group className={classes.previewImage}>
              <div className={classes.iconPanel}>
                {!formState.isSubmitting && (
                  <ActionIcon onClick={removePhoto} variant="filled" radius="xl" color={'primary'}>
                    <MdClose size="16" />
                  </ActionIcon>
                )}
              </div>
              <img className={classes.imageTypeSelect} src={selectedPhoto} alt="image" />
            </Group>
          )}
        </div>
      )}

      {showKidsImage && (
        <Button className={'w-100'} size={'sm'} onClick={selectPhotoKids}>
          {t('send_kids_image')}
        </Button>
      )}

      <Group className={'w-100 gap-0'}>
        <Group className={classes.sectionInput}>
          <TextInputController
            control={control}
            name={'body'}
            id={'body'}
            placeholder={`${t('write_a_message')}...`}
            disabled={formState.isSubmitting || moment().isAfter(lastDate)}
            meta={{
              error: null,
            }}
            fieldRef={props.inputRef}
            maxLength={10000}
            onKeyPress={onKeyPress}
            autoComplete="off"
            size={mobileView ? 'md' : 'lg'}
            className="w-100"
          />
        </Group>
        <Group className={classes.sectionIcons}>
          <ActionIcon
            disabled={formState.isSubmitting || moment().isAfter(lastDate)}
            onClick={() => changePicker()}
            variant="transparent"
            loading={formState.isSubmitting || imageSubmitting}
            color={'secondary'}
          >
            {pickerShown ? <MdClose size="24" /> : <MdEmojiEmotions size="24" />}
          </ActionIcon>
          <ActionIcon
            disabled={formState.isSubmitting || moment().isAfter(lastDate) || imageSubmitting}
            onClick={selectPhoto}
            variant="transparent"
            loading={formState.isSubmitting || imageSubmitting}
            color={'secondary'}
          >
            <MdOutlineImage size="24" />
          </ActionIcon>
          <ActionIcon
            disabled={
              formState.isSubmitting ||
              (!watchBody && !selectedPhoto) ||
              moment().isAfter(lastDate) ||
              imageSubmitting
            }
            onClick={beforeSubmit}
            variant="transparent"
            loading={formState.isSubmitting || imageSubmitting}
            color={'primary'}
          >
            <MdSend size="24" />
          </ActionIcon>
        </Group>
      </Group>
    </Stack>
  )
}

const mapStateToProps = (state: RootState) => {
  const { activeChat } = state.chat.conversation.data

  return {
    activeChat,
  }
}

export const InputSection = connect(mapStateToProps)(InputSectionComponent)
