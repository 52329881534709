import React from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar } from '@mantine/core'
import { AvatarProvider } from '@/components/Elements'
import { MessageType } from '@/features/chat/types/models'
import { Message } from '../Message'
import { Day } from './Day'
import useStyles from './MessageItem.styles'
import { Unread } from './Unread'

interface IProps {
  item: MessageType
  config: any
  forwardRef: any
}

export const MessageItem = ({ item, config, forwardRef }: IProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  return (
    <div
      className={cx(classes.root, {
        [`${classes.right}`]: item.is_my,
      })}
      ref={forwardRef}
    >
      <div className={'w-full'}>
        {config.showDate && <Day date={item.created_at} />}

        {config.showUnread && <Unread />}

        <div
          className={cx(classes.messageBlock, {
            [`${classes.right}`]: item.is_my,
          })}
        >
          {!item.is_my && (
            <>
              {config.showAvatar ? (
                <div className={classes.avatarGap}>
                  <AvatarProvider size={32} avatar={item?.user?.avatar} />
                </div>
              ) : (
                <div className={classes.avatarHidden} />
              )}
            </>
          )}
          <Message item={item} showName={config.showName} />
        </div>
      </div>
    </div>
  )
}
