import React from 'react'
import { useFormContext } from 'react-hook-form'
//import { useTranslation } from 'react-i18next'
import { Space } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import { BookingTimetableFormConnector } from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/BookingTimetableFormConnector'
import { LanguagesFormSection } from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/Forms/CareDetailsForm/FormSections'
import { HoursPerWeekFormSection } from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/Forms/GeneralForm/HoursPerWeekFormSection/HoursPerWeekFormSection'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
//import { useBookingPeriod } from '../../hooks'
import { BookingLocation } from '../../types'
import { BookingPeriodFormSection } from './BookingPeriodFormSection'
import { LocationFormSection } from './LocationFormSection/LocationFormSection'
import { TimetableSection } from './TimetableSection'

interface IProps {
  location: BookingLocation | null
  locationNotFound: boolean
  onLocationNotFound: (notFound: boolean) => void
  onLocationChange: (data: BookingLocation | null) => void
}

export const DataForm = ({
  location,
  onLocationChange,
  locationNotFound,
  onLocationNotFound,
}: IProps) => {
  const { watch } = useFormContext()
  const watchType = watch('type')
  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <>
      <BookingTimetableFormConnector />

      {/*<TypeFormSection />*/}

      <BookingPeriodFormSection />

      {watchType !== BOOKING_TYPE.PERMANENT_NANNY && <TimetableSection />}

      {watchType === BOOKING_TYPE.PERMANENT_NANNY && <HoursPerWeekFormSection />}
      {/*valid && watchType === BOOKING_TYPE.LIVE_IN && <TimetableSectionWeek />*/}

      {/*<InfoFormSection />*/}

      <LocationFormSection
        location={location}
        onLocationChange={onLocationChange}
        locationNotFound={locationNotFound}
        onLocationNotFound={onLocationNotFound}
        boldTitle={true}
      />

      <LanguagesFormSection boldTitle={true} />

      <Space h={'xl'} />
      {mobileView && <Space h={'xl'} />}
    </>
  )
}
