import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useEvents } from '@/features/app/hooks'
import { ROLE } from '@/features/user'

interface IProps {
  [x: string]: any
}

export const PanelSwitch: React.FC<IProps> = ({ children, roles, ...props }) => {
  const location = useLocation()
  const events = useEvents()

  const { user } = props

  const path =
    user.role === ROLE.Client
      ? user.has_bookings
        ? '/bookings'
        : '/book-now'
      : '/services/upcoming'

  return <Navigate to={path} state={{ from: location }} />
}
