import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    backgroundColor: theme.fn.rgba(theme.colors.gray[3], 0.5),
    padding: '4px 12px',
    borderRadius: '12px',
  },

  highlight: {
    backgroundColor: theme.fn.rgba(theme.colors.secondary[theme.fn.primaryShade()], 0.5),
  },
  image: {
    display: 'flex',
    marginTop: '8px',
  },
  imageType: {
    maxHeight: '200px',
    maxWidth: '200px',
    cursor: 'pointer',
  },
  imagePopup: {
    maxHeight: '100vh',
    maxWidth: '100vw',
  },
  bodyText: {
    fontSize: '16px',
  },
  nameText: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'primary',
  },
}))
