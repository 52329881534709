import React from 'react'
import { ChatNavControl } from '@/features/chat/components/Elements'
import { ChatModel } from '@/features/chat/types/models'
import { BabysitterUser, ClientUser } from '@/features/user'

interface IProps {
  client: ClientUser
  chat?: ChatModel
  participants?: BabysitterUser[]
}

export const ChatControl = ({ client, participants, chat }: IProps) => {
  const participantsList = []
  participantsList.push({
    id: client.id,
    avatar: client.avatar,
    first_name: client.first_name,
    last_name: client.last_name,
    role: client.role,
  })

  if (participants)
    participants.map((participant) => {
      participantsList.push({
        id: participant.id,
        avatar: participant.avatar,
        first_name: participant.first_name,
        last_name: participant.last_name,
        role: participant.role,
      })
    })
  return <ChatNavControl chat={chat} participants={participantsList} />
}
