import { IconArrowLeft } from '@tabler/icons'
import { useEffect } from 'react'
import { Text, useMantineTheme } from '@mantine/core'
import { MobileHeaderModal } from '@/components/Elements/Header/MobileHeader'
import { MobileFullModal } from '@/components/Elements/Modals/MobileFullModal'
import { useAppState } from '@/features/app/hooks'
import { useChatConversationData } from '@/features/chat/hooks'
import { usePopup } from '@/hooks'
import useStyles from './Chat.styles'
import { Room } from './Room'
import { MobileHeader, MobileHeaderRightBabysitters } from './Room/Header'
import { Sidebar } from './Sidebar'

export const Chat = () => {
  const {
    appState: { mobileView },
  } = useAppState()
  const theme = useMantineTheme()
  const { classes } = useStyles()

  const { visible, open, close } = usePopup()
  const { getParticipants } = useChatConversationData()
  const participants = getParticipants()

  const openRoom = () => {
    open(true)
  }

  useEffect(() => {
    if (mobileView && participants && participants[0]?.role) {
      openRoom()
    }
  }, [])

  return (
    <div className={classes.root}>
      {mobileView ? (
        <>
          <Sidebar openRoom={openRoom} />

          <MobileFullModal
            opened={visible}
            leftIcon={'arrow_left'}
            onClose={close}
            headerBorder={true}
            scrollable={false}
            header={
              <div className={'flex justify-center'}>
                <MobileHeaderModal
                  border={true}
                  left={
                    <IconArrowLeft
                      size={20}
                      color={theme.colors.primary[theme.fn.primaryShade()]}
                      onClick={close}
                    />
                  }
                  right={<MobileHeaderRightBabysitters />}
                  title={<MobileHeader />}
                />
              </div>
            }
          >
            <Room />
          </MobileFullModal>
        </>
      ) : (
        <>
          <Sidebar />

          <Room />
        </>
      )}
    </div>
  )
}
