import moment from 'moment'
import React, { useEffect } from 'react'
import { UnstyledButton } from '@mantine/core'
import { AvatarProvider } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { ChatModel } from '@/features/chat/types/models'
import { ROLE, useUser } from '@/features/user'
import { useUsersModel } from '@/features/users/hooks'
import { Body } from './Body'
import useStyles from './Item.styles'

interface IProps {
  data: ChatModel
  onSelect: (data: ChatModel) => void
  active: boolean
}

export const Item = ({ data, active, onSelect }: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()

  const { classes, cx } = useStyles()

  const { participants } = data

  const { getAllParticipantsRole } = useUsersModel()
  const { isClient, isBabysitter } = useUser()
  const [hour, minute] = data?.booking?.time_end?.split(':') ?? [0, 0]
  /*
  const config = {
    avatar: isClient(),
  }*/

  const display =
    !data?.booking ||
    moment(data.booking.date_end).hour(hour).minute(minute).isAfter(moment()) ||
    data.last_message

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    if (active) {
      return
    }

    onSelect(data)
  }

  return (
    <>
      {display && (
        <UnstyledButton
          className={cx(
            classes.root,
            isClient() || getAllParticipantsRole(participants, ROLE.Babysitter).length == 1
              ? classes.rootClient
              : classes.rootBabysitter,
            {
              [`${classes.rootActive}`]: active,
            }
          )}
          onClick={onClick}
        >
          <div className={classes.avatar}>
            <AvatarProvider
              size={mobileView ? 42 : 48}
              multiUser={participants && participants.length > 2}
              avatar={
                isClient() && participants && participants.length == 2
                  ? participants[0].avatar
                  : null
              }
            />
          </div>

          <div className={classes.body}>
            <Body data={data} />
          </div>
        </UnstyledButton>
      )}
    </>
  )
}
