import { useNavigate } from 'react-router-dom'
import { Group, Text } from '@mantine/core'
import { AvatarProvider } from '@/components/Elements'
import { useChatConversationData } from '@/features/chat/hooks'
import { ROLE, useUser } from '@/features/user'
import { useUsersModel } from '@/features/users/hooks'

export const MobileHeader = () => {
  const { isClient, isBabysitter } = useUser()
  const navigate = useNavigate()
  const { getName } = useUsersModel()
  const { getParticipants, getParticipantsBabysitters, getParticipantsClient } =
    useChatConversationData()
  const participants = isClient() ? getParticipantsBabysitters() : getParticipants()
  const participantClient = getParticipantsClient()

  const goToProfile = (role: ROLE | undefined, id: number) => {
    if (isClient() && role && role === ROLE.Babysitter) navigate('/babysitters/' + id)
  }

  return (
    <div className={'flex'}>
      {isClient() && participants && participants.length == 1 && (
        <Group
          className={'gap-1'}
          onClick={() => goToProfile(participants[0].role, participants[0].id)}
        >
          {participants[0].role === ROLE.Babysitter && (
            <AvatarProvider size={32} avatar={participants[0]?.avatar} />
          )}
          <Text size={16} weight={600} className="mt-1">
            {getName(participants[0])}
          </Text>
        </Group>
      )}
      {isBabysitter() && participantClient && participantClient.length == 1 && (
        <Group
          className={'gap-1'}
          onClick={() => goToProfile(participantClient[0].role, participantClient[0].id)}
        >
          <Text size={16} weight={600} className="mt-1">
            {getName(participantClient[0])}
          </Text>
        </Group>
      )}

      {isClient() && participants && participants.length > 1 && (
        <Group className={'gap-1'}>
          {participants?.map((item, index) => (
            <>
              {item.role === ROLE.Babysitter && (
                <div key={'profile' + index} onClick={() => goToProfile(item.role, item.id)}>
                  <AvatarProvider size={32} avatar={item.avatar} />
                </div>
              )}
            </>
          ))}
        </Group>
      )}
    </div>
  )
}
