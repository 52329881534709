import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  wrap: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      height: '100%',
      backgroundColor: 'white',
      padding: '32px 32px',
      borderTopLeftRadius: '32px',
      borderTopRightRadius: '32px',
    },
  },
}))
