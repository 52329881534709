import { IconChevronDown, IconRotate, IconTrash } from '@tabler/icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ActionIcon, Button, Menu, Text, useMantineTheme } from '@mantine/core'
import { Icon, Modal } from '@/components/Elements'
import { useAppState, useEvents } from '@/features/app/hooks'
import { previousRequestState } from '@/features/booking/store/previousRequest.slice'
import { BookingCancelProvider } from '@/features/bookings/components/BookingCancel'
import { BOOKING_STATUS, BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { getType } from '@/features/bookings/helpers/booking/type'
import { BookingModel } from '@/features/bookings/types/models'
import { usePopup } from '@/hooks'

interface IProps {
  config: {
    cancel: boolean
  }
  dots?: boolean
  booking: BookingModel
  onCancelSuccess?: () => void
}

export enum OPTIONS_MENU {
  BOOKING_OPTIONS = 'options',
  BOOKING_CANCEL = 'cancel',
  BOOKING_OPTIONS_CANCELED = 'options_canceled',
}
const defaultConfig = {
  cancel: true,
}

export const ActionMenu = ({ config, dots = false, booking, onCancelSuccess }: IProps) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    appState: { mobileView },
  } = useAppState()
  const events = useEvents()
  const [type, setType] = useState(OPTIONS_MENU.BOOKING_OPTIONS)

  config = { ...defaultConfig, ...config }

  // Cancel
  const { visible, open, close } = usePopup()

  const handleNew = async () => {
    const type = booking.type === BOOKING_TYPE.REGULAR ? BOOKING_TYPE.ONETIME : booking.type

    events.actions.newBooking({ Type: getType(type).title })

    await dispatch(previousRequestState.setBooking(booking))
    navigate(getType(type).url)
  }
  const onBookingCancelSuccess = () => {
    close()

    onCancelSuccess && onCancelSuccess()
  }

  const onBookingRequestAgainClick = (e: any) => {
    console.log('onBookingCancelClick')
    e.stopPropagation()
    handleNew()
    close()
  }
  const onBookingCancelClick = (e: any) => {
    console.log('onBookingCancelClick')
    e.stopPropagation()
    setType(OPTIONS_MENU.BOOKING_OPTIONS)
    open(false)
  }

  const onBookingOptionsClick = (e: any) => {
    e.stopPropagation()
    console.log('onBookingOptionsClick')
    setType(OPTIONS_MENU.BOOKING_CANCEL)
    open(false)
  }

  const onBookingCancelledClick = (e: any) => {
    e.stopPropagation()
    console.log('onBookingCancelledClick')
    setType(OPTIONS_MENU.BOOKING_OPTIONS_CANCELED)
    open(false)
  }

  return (
    <>
      {!mobileView && (
        <>
          {booking?.status == BOOKING_STATUS.FINISHED ? (
            <Button className={'w-100'} size={'sm'} onClick={onBookingRequestAgainClick}>
              {t('request_again')}
            </Button>
          ) : (
            <Menu width={mobileView ? '100%' : '150'}>
              <Menu.Target>
                <Button
                  className={mobileView ? 'w-100' : ''}
                  size={'sm'}
                  rightIcon={<IconChevronDown size={16} />}
                >
                  {t('modify')}
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                {config?.cancel && (
                  <Menu.Item
                    icon={
                      <IconTrash size={20} color={theme.colors.primary[theme.fn.primaryShade()]} />
                    }
                    onClick={onBookingCancelClick}
                  >
                    <Text color={'primary'}>{t('cancel')}</Text>
                  </Menu.Item>
                )}
              </Menu.Dropdown>
            </Menu>
          )}
        </>
      )}
      {mobileView && dots && (
        <ActionIcon
          size={'lg'}
          color={'gray'}
          radius="md"
          ml={'auto'}
          onClick={onBookingCancelledClick}
        >
          <Icon name={'dots'} color={'grey'} />
        </ActionIcon>
      )}
      {mobileView && !dots && (
        <Button
          className={'w-100 mt-2'}
          size={'sm'}
          onClick={
            booking?.status == BOOKING_STATUS.FINISHED
              ? onBookingRequestAgainClick
              : onBookingOptionsClick
          }
        >
          {t(booking?.status == BOOKING_STATUS.FINISHED ? 'request_again' : 'modify')}
        </Button>
      )}

      <Modal
        opened={visible}
        onClose={close}
        centered
        title={type == OPTIONS_MENU.BOOKING_OPTIONS ? `${t('cancel_booking')}?` : `${t('modify')}`}
      >
        {type == OPTIONS_MENU.BOOKING_OPTIONS && (
          <BookingCancelProvider
            id={booking?.id}
            onSuccess={onBookingCancelSuccess}
            onCancel={close}
          />
        )}
        {type == OPTIONS_MENU.BOOKING_CANCEL && (
          <div className={'flex flex-col items-center'}>
            <div className={'w-full'}>
              <div className={'flex w-100 pointer'} onClick={onBookingCancelClick}>
                <IconTrash size={24} color={theme.colors.primary[theme.fn.primaryShade()]} />
                <Text className={'pl-3'} size={18} color={'primary'}>
                  {t('cancel')}
                </Text>
              </div>
            </div>
          </div>
        )}
        {type == OPTIONS_MENU.BOOKING_OPTIONS_CANCELED && (
          <div className={'flex flex-col items-center'}>
            <div className={'w-full'}>
              <div className={'flex w-100 pointer'} onClick={onBookingRequestAgainClick}>
                <IconRotate size={24} />
                <Text className={'pl-3'} size={18} color={'primary'}>
                  {t('request_again')}
                </Text>
              </div>
              <div className={'flex w-100 pointer mt-4'} onClick={onBookingCancelClick}>
                <IconTrash size={24} color={theme.colors.primary[theme.fn.primaryShade()]} />
                <Text className={'pl-3'} size={18} color={'primary'}>
                  {t('delete')}
                </Text>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}
