import { Link } from 'react-router-dom'
import { Anchor, Group, Stack } from '@mantine/core'
import { AvatarProvider } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { useBookingPermissions } from '@/features/bookings/hooks'
import { BookingModel } from '@/features/bookings/types/models'
import { BookingDetails, RatingControl } from './Controls'

interface IProps {
  config: {
    details: boolean
    rating: boolean
  }
  booking: BookingModel
  onView: (id: number) => void
  ratingControlProps?: any
}

const defaultConfig = {
  details: true,
  rating: false,
}

export const Footer = ({ config, booking, onView, ratingControlProps }: IProps) => {
  config = {
    ...defaultConfig,
    ...config,
  }

  const { isRatingAllow } = useBookingPermissions()

  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <div className={'flex flex-wrap gap-3 grow'}>
      <Stack className={'gap-1'}>
        {config?.rating && booking.babysitters && (
          <>
            {booking.babysitters.map((item, index) => (
              <>
                {isRatingAllow(booking, item) && (
                  <Group spacing={8} noWrap key={'footer-rating-' + index}>
                    {booking.babysitters && booking.babysitters.length > 1 && (
                      <Anchor component={Link} to={`/babysitters/${item?.id}`}>
                        <AvatarProvider size={mobileView ? 30 : 32} avatar={item?.avatar} />
                      </Anchor>
                    )}

                    <RatingControl booking={booking} babysitter={item} {...ratingControlProps} />
                  </Group>
                )}
              </>
            ))}
          </>
        )}
        {booking.kids_image && (
          <img
            width={'100%'}
            style={{ maxWidth: '300px' }}
            src={process.env.REACT_APP_BASE_URL + 'api/chats/chat-image/' + booking.kids_image}
            alt="image"
          />
        )}
      </Stack>

      {config?.details && (
        <div className={'flex flex-wrap gap-3 grow justify-end'}>
          <BookingDetails id={booking?.id} onView={onView} />
        </div>
      )}
    </div>
  )
}
