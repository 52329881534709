import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export const BookingCheckoutDetails = () => {
  const { t } = useTranslation()
  const { booking } = useSelector((state: any) => state.booking.checkout)
  return (
    booking && (
      <div className="bg-white rounded-lg shadow-sm mb-8 p-4">
        <div className="text-md mt-2">
          <span className="font-bold">{t('checkout.title')}</span> {t('checkout.subtitle')}
        </div>
        <div className="text-md mt-1">{t('checkout.text')}</div>
      </div>
    )
  )
}
