import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import { MyBookingCandidateType } from '@/features/booking-candidate/types/models'
import { BookingService } from '@/features/bookings/types/models'
import { ActionMenu } from './ActionMenu'
import { CandidateStatusBlock } from './Blocks'
import { ChatControl } from './Controls'

interface IProps {
  config: {
    candidateStatusBlock: boolean
    menu: boolean
    chat: boolean
  }
  menu: {
    config: {
      cancel: boolean
    }
  }
  bookingService: BookingService
  candidateProps?: {
    candidate: MyBookingCandidateType
    acceptControlProps: {
      onSuccess?: (data: object) => void
    }
    declineControlProps: {
      onSuccess?: (data: object) => void
    }
  }
  onViewDetailsClick?: () => void
  onCancelSuccess?: () => void
}

export const ActionBar = ({
  config,
  menu,
  bookingService,
  onViewDetailsClick,
  candidateProps,
  onCancelSuccess,
}: IProps) => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <div className={mobileView ? 'flex flex-col gap-2 w-100' : 'flex gap-4 items-center'}>
      {!mobileView ? (
        <>
          {config?.candidateStatusBlock && candidateProps && (
            <CandidateStatusBlock {...candidateProps} />
          )}

          {config?.chat && bookingService?.user && (
            <ChatControl
              chat={bookingService?.chat}
              client={bookingService?.user}
              participants={bookingService?.babysitters}
            />
          )}

          {config?.menu && (
            <ActionMenu
              {...menu}
              bookingService={bookingService}
              onCancelSuccess={onCancelSuccess}
            />
          )}
        </>
      ) : (
        <div className={'flex w-100'}>
          {config?.candidateStatusBlock && candidateProps ? (
            <Button
              className={'w-100'}
              size={'sm'}
              onClick={onViewDetailsClick}
              variant={'outline'}
            >
              {t('show_details')}
            </Button>
          ) : (
            <>
              <div className={'w-1/5'}>
                {config?.chat && bookingService?.user && (
                  <ChatControl
                    chat={bookingService?.chat}
                    client={bookingService?.user}
                    participants={bookingService?.babysitters}
                  />
                )}
              </div>

              {config?.menu && (
                <div className={'w-4/5'}>
                  <ActionMenu
                    {...menu}
                    bookingService={bookingService}
                    onCancelSuccess={onCancelSuccess}
                  />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}
