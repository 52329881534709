import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Badge } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { BookingNewBadge } from '@/features/bookings/components/Elements'
import { BOOKING_STATUS } from '@/features/bookings/consts/booking'
import { BookingModel } from '@/features/bookings/types/models'
import { ActionMenu } from './ActionMenu'
import { ChatControl } from './Controls'

interface IProps {
  config: {
    favorite: boolean
    menu: boolean
    chat: boolean
    rating: boolean
    upcomingPanel: boolean
  }
  menu: {
    config: {
      cancel: boolean
    }
  }
  booking: BookingModel
  onCancelSuccess?: () => void
  favoriteControlProps?: any
}

interface StatusInfo {
  text: string
  color: string
}

const statuses: Record<BOOKING_STATUS, StatusInfo> = {
  unpaid: {
    text: 'unpaid',
    color: 'yellow',
  },
  waiting_payment: {
    text: 'waiting_payment',
    color: 'yellow',
  },
  canceled_client: {
    text: 'canceled_client',
    color: 'blue',
  },
  canceled_babysitter: {
    text: 'canceled_babysitter',
    color: 'blue',
  },
  finished: {
    text: 'finished',
    color: 'success',
  },
  waiting_babysitter: {
    text: 'waiting_babysitter',
    color: 'yellow',
  },
  babysitter_not_found: {
    text: 'babysitter_not_found',
    color: 'yellow',
  },
  upcoming: {
    text: 'upcoming',
    color: 'secondary',
  },
  request_finish: {
    text: 'request_finish',
    color: 'blue',
  },
  payment_link_error: {
    text: 'payment_link_error',
    color: 'yellow',
  },
  canceled_late_payment: {
    text: 'canceled_late_payment',
    color: 'blue',
  },
}

export const ActionBar = ({ config, menu, booking, onCancelSuccess }: IProps) => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <div className={mobileView ? 'flex flex-col gap-2 w-100' : 'flex flex-col gap-2 items-end'}>
      {!mobileView && (
        <div className={'flex gap-4 items-center justify-center w-100'}>
          <div className={'flex flex-column gap-1'}>
            {booking.is_new && <BookingNewBadge />}

            <Badge color={statuses[booking.status]?.color} variant={'filled'} fullWidth>
              {t(statuses[booking.status]?.text)}
            </Badge>
          </div>
          {/*config?.favorite && booking?.babysitter && (
            <FavoriteControl babysitter={booking?.babysitter} {...favoriteControlProps} />
          )*/}

          {config?.chat && booking?.babysitters && booking.babysitters.length > 0 && (
            <ChatControl chat={booking?.chat} babysitters={booking?.babysitters} />
          )}

          {config?.menu ||
            (booking.status == BOOKING_STATUS.FINISHED && (
              <ActionMenu {...menu} booking={booking} onCancelSuccess={onCancelSuccess} />
            ))}
        </div>
      )}
      {mobileView && (config?.upcomingPanel || booking.status == BOOKING_STATUS.FINISHED) && (
        <>
          <div className={'flex flex-column gap-4 items-center justify-center w-100'}>
            <div className={'flex flex-column gap-1'}>
              {booking.is_new && <BookingNewBadge />}

              {booking.status != BOOKING_STATUS.FINISHED && (
                <Badge color={statuses[booking.status]?.color} variant={'filled'} fullWidth>
                  {t(statuses[booking.status]?.text)}
                </Badge>
              )}
            </div>

            {booking.kids_image && (
              <img
                width={'100%'}
                style={{ maxWidth: '300px' }}
                src={process.env.REACT_APP_BASE_URL + 'api/chats/chat-image/' + booking.kids_image}
                alt="image"
              />
            )}

            {/*config?.favorite && booking?.babysitter && (
              <FavoriteControl babysitter={booking?.babysitter} {...favoriteControlProps} />
            )*/}

            {/*config?.chat && booking?.babysitters && booking.babysitters.length > 0 && (
              <ChatControl chat={booking?.chat} babysitter={booking?.babysitters[0]} /> //TODO
            )*/}
          </div>
          {config?.menu ||
            (booking.status == BOOKING_STATUS.FINISHED && (
              <div className={'flex gap-4 items-center justify-center w-100'}>
                <div className={'w-75'}>
                  <ActionMenu {...menu} booking={booking} onCancelSuccess={onCancelSuccess} />
                </div>
              </div>
            ))}
        </>
      )}
      {mobileView && !config?.upcomingPanel && booking.status != BOOKING_STATUS.FINISHED && (
        <div className={'flex gap-4 items-center justify-center w-100'}>
          <div className={'flex w-100 flex-column gap-1'}>
            <Badge color={statuses[booking.status]?.color} variant={'filled'} fullWidth>
              {t(statuses[booking.status]?.text)}
            </Badge>

            <ActionMenu {...menu} dots={true} booking={booking} onCancelSuccess={onCancelSuccess} />
          </div>
        </div>
      )}
      {booking.status == BOOKING_STATUS.BABYSITTER_NOT_FOUND && (
        <div className={'flex gap-4 items-center justify-center w-100'}>
          <div className="w-2/3 text-right">
            <small>
              We’re very sorry, we couldn't find a babysitter tailored to your needs. Please contact
              us for a refund at info@babysisters.pt
            </small>
          </div>
        </div>
      )}
    </div>
  )
}
