import moment from 'moment'
import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { ScrollArea } from '@mantine/core'
import { useAppState, useEvents } from '@/features/app/hooks'
import { useChatMessagesScroll } from '@/features/chat/hooks'
import { chatConversationMessagesList } from '@/features/chat/store'
import { ChatModel, MessageType } from '@/features/chat/types/models'
import { TAG_MANAGER_EVENT } from '@/features/tag-manager/consts/event'
import { useUser } from '@/features/user'
import { useNotify } from '@/hooks'
import { RootState } from '@/store'
import { MessageItem } from './MessageItem'
import useStyles from './MessagesContainer.styles'
import { MessagesList } from './MessagesList'

interface IProps {
  list: MessageType[]
  loading: boolean
  error: any
  isMoreLoading: boolean
  meta: any
  activeChat: ChatModel | null
  isGroup: boolean
}

const MessagesContainerComponent = (props: IProps) => {
  const { t } = useTranslation()
  const { isBabysitter } = useUser()
  const event = useEvents()

  const dispatch = useDispatch()

  const { classes } = useStyles()

  const { showNotification } = useNotify()

  const messagesRef = useRef<any>([])
  const scrollRef = useRef<any>(null)
  const lastMessageRef = useRef<any>(null)

  // messages scroll logic (scroll to unread message and etc.)
  useChatMessagesScroll({
    messages: props.list,
    messagesLoading: props.loading,
    messagesIsMoreLoading: props.isMoreLoading,
    scrollRef,
    lastMessageRef,
    messagesRef,
  })

  useEffect(() => {
    fetchData({})

    return () => {
      dispatch(chatConversationMessagesList.cleanState())
    }
  }, [props.activeChat])

  useEffect(() => {
    if (props.error) {
      // console.log('MessagesContainerComponent', props.error)
      const message = props.error || t('error')
      showNotification({ type: 'error', message: message })
    }
  }, [props.error])

  const {
    appState: { windowFocus },
  } = useAppState()

  useEffect(() => {
    // if app set in background
    if (!windowFocus) {
      // mark received messages seen
      dispatch(chatConversationMessagesList.markReceivedMessagesSeen())
    }
  }, [windowFocus])

  const fetchData = (params = {}, state = {}) => {
    event.actions.genericProps(TAG_MANAGER_EVENT.CHAT_OPEN, { chatId: props.activeChat?.id })
    const payload = {
      params,
      state,
      activeChat: props.activeChat,
    }
    // console.log('MessagesContainer fetchData', payload)
    dispatch(chatConversationMessagesList.getList(payload))
  }

  const hasMore = useMemo(() => {
    const { meta } = props

    if (!meta) {
      return false
    }

    const { current_first_id, chat_first_id } = meta

    return current_first_id && chat_first_id && current_first_id !== chat_first_id
  }, [props.meta])

  const onLoadMore = () => {
    if (!hasMore) {
      return
    }

    const { meta } = props

    if (hasMore && !props.loading && !props.isMoreLoading) {
      fetchData({ last_message_id: meta.current_first_id }, { isMoreLoading: true })
    }
  }

  const renderMessages = () => {
    if (messagesRef.current) {
      messagesRef.current = []
    }

    const list = [...props.list].reverse()

    const messages: any[] = []

    for (let i = 0; i < list.length; i++) {
      const message = list[i]

      const prevMessage = list[i - 1]

      let withDay = true
      let withName = true

      if (prevMessage) {
        // check if prev message has day separator
        withDay = !moment(prevMessage.created_at).isSame(moment(message.created_at), 'day')
        withName =
          ((props.isGroup || isBabysitter()) &&
            (prevMessage.user?.id != message.user?.id ||
              !moment(prevMessage.created_at).isSame(moment(message.created_at), 'hour'))) ||
          false
      }

      // if message not mine and user not seen the message
      let withUnread = !message.is_my && !message.seen
      if (prevMessage && withUnread) {
        // check if prev message has unread separator
        if (!prevMessage.is_my) {
          withUnread = prevMessage.seen
        }
      }

      const config = {
        showDate: withDay,
        showUnread: withUnread,
        showName: withName && !message.is_my,
        showAvatar: withName && !message.is_my,
      }

      messages.push(
        <MessageItem
          item={message}
          key={`message-${message.id}`}
          config={config}
          forwardRef={(el: any) => (messagesRef.current[message.id] = el)}
        />
      )
    }

    return messages.map((item) => item)
  }

  return (
    <ScrollArea className={'h-full'}>
      <div className={classes.root}>
        <MessagesList
          scrollRef={scrollRef}
          lastMessageRef={lastMessageRef}
          items={props.list}
          loading={props.loading}
          isMoreLoading={props.isMoreLoading}
          onLoadMore={onLoadMore}
          renderMessages={renderMessages}
          hasMore={hasMore}
        />
      </div>
    </ScrollArea>
  )
}

const mapStateToProps = (state: RootState) => {
  const { list, loading, error, isMoreLoading, meta } = state.chat.conversation.messages.list

  return {
    list,
    loading,
    error,
    isMoreLoading,
    meta,
    activeChat: state.chat.conversation.data.activeChat,
    isGroup:
      (state.chat.conversation.data.data &&
        state.chat.conversation.data.data.participants &&
        state.chat.conversation.data.data.participants.length > 1) ||
      false,
  }
}

export const MessagesContainer = connect(mapStateToProps)(MessagesContainerComponent)
