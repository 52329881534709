import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { ActionIcon, useMantineTheme } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { chatConversationData } from '@/features/chat/store'
import { ChatModel, Participant } from '@/features/chat/types/models'

interface IProps {
  participants: Participant[]
  chat?: ChatModel
}

export const ChatNavControl = ({ participants, chat }: IProps) => {
  const theme = useMantineTheme()
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const navigateToChat = (e: any) => {
    e.stopPropagation()

    dispatch(chatConversationData.initChat(chat))
    dispatch(chatConversationData.initParticipants(participants))
    navigate(`/chat`)
  }

  return (
    <ActionIcon
      onClick={navigateToChat}
      component="div"
      size={'lg'}
      color={'gray'}
      radius="xl"
      variant={'light'}
    >
      <Icon name={'chat'} color={theme.black} />
    </ActionIcon>
  )
}
